import { Metadata, Asset, Audience, Environment } from '@workspaces/types'
import { AssetsService } from '@workspaces/services'

export default {
  async getAssetsToShowBasedOnAudienceFilter(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    queryParams: Asset.AssetSubFilter,
  ): Promise<string[]> {
    const data = await AssetsService.getAssetsToShowBasedOnAudienceFilter(
      meta,
      environment,
      queryParams,
    )

    return data
  },

  async getAssetsToShowBasedOnAudienceFilterV2(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    queryParams: Audience.AudiencesForfilteringAssetsQueryParams,
  ): Promise<Asset.AssetIdIndex[]> {
    const data = await AssetsService.getAssetsToShowBasedOnAudienceFilterV2(
      meta,
      environment,
      queryParams,
    )

    return data
  },

  async getAssetsTypeL1DigitalFilter(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    queryParams: Asset.AssetSubFilter,
  ): Promise<Asset.AssetTypeL1[]> {
    const data = await AssetsService.getAssetsTypeL1DigitalFilter(
      meta,
      environment,
      queryParams,
    )

    return data
  },

  async getAssetsTypeL1StaticFilter(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    queryParams: Asset.AssetSubFilter,
  ): Promise<Asset.AssetTypeL1[]> {
    const data = await AssetsService.getAssetsTypeL1StaticFilter(
      meta,
      environment,
      queryParams,
    )

    return data
  },

  async getAssetsTypeL1(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    queryParams: Asset.AssetSubFilter,
  ): Promise<Asset.AssetTypeLX[]> {
    const data = await AssetsService.getAssetsTypeL1(
      meta,
      environment,
      queryParams,
    )

    return data
  },

  async getAssetsTypeL2(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    queryParams: Asset.AssetSubFilter,
  ): Promise<Asset.AssetTypeLX[]> {
    const data = await AssetsService.getAssetsTypeL2(
      meta,
      environment,
      queryParams,
    )

    return data
  },

  async getAssetsTypeL3(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    queryParams: Asset.AssetSubFilter,
  ): Promise<Asset.AssetTypeLX[]> {
    const data = await AssetsService.getAssetsTypeL3(
      meta,
      environment,
      queryParams,
    )

    return data
  },

  async getAssetsTypeL4(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    queryParams: Asset.AssetSubFilter,
  ): Promise<Asset.AssetTypeLX[]> {
    const data = await AssetsService.getAssetsTypeL4(
      meta,
      environment,
      queryParams,
    )

    return data
  },

  async getAssetsTypeL5(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    queryParams: Asset.AssetSubFilter,
  ): Promise<Asset.AssetTypeLX[]> {
    const data = await AssetsService.getAssetsTypeL5(
      meta,
      environment,
      queryParams,
    )

    return data
  },

  async getAssetsTypeL6(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    queryParams: Asset.AssetSubFilter,
  ): Promise<Asset.AssetTypeLX[]> {
    const data = await AssetsService.getAssetsTypeL6(
      meta,
      environment,
      queryParams,
    )

    return data
  },

  async getAssetsTypeL7(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    queryParams: Asset.AssetSubFilter,
  ): Promise<Asset.AssetTypeLX[]> {
    const data = await AssetsService.getAssetsTypeL7(
      meta,
      environment,
      queryParams,
    )

    return data
  },

  async getAssetsTypeL8(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    queryParams: Asset.AssetSubFilter,
  ): Promise<Asset.AssetTypeLX[]> {
    const data = await AssetsService.getAssetsTypeL8(
      meta,
      environment,
      queryParams,
    )

    return data
  },

  async getAssetsTypeL9(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    queryParams: Asset.AssetSubFilter,
  ): Promise<Asset.AssetTypeLX[]> {
    const data = await AssetsService.getAssetsTypeL9(
      meta,
      environment,
      queryParams,
    )

    return data
  },

  async getAssetById(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    id: string,
  ): Promise<Asset.AssetFull[]> {
    const data = await AssetsService.getAssetById(meta, environment, id)

    return data
  },

  async getAssetsByMinimumDistance(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    ids: string[],
    distance: number,
    chuncksSize: number,
  ): Promise<Asset.AssetId[]> {
    const data = await AssetsService.getAssetsByMinimumDistance(
      meta,
      environment,
      ids,
      distance,
      chuncksSize,
    )
    return data
  },

  async getAidaAssetsFromFile(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    fileName: string,
  ): Promise<Asset.AssetId[]> {
    const data = await AssetsService.getAidaAssetsFromFile(
      meta,
      environment,
      fileName,
    )
    return data
  },
}
