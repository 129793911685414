import { Package } from '@workspaces/types'
// import { numberFormat } from '@/filters/numberFormat'

function formatToNumber(value: string | number): number {
  let valueFormatted = 0
  if (typeof value === 'string') {
    valueFormatted = Number(value)
  } else if (typeof value === 'number') {
    valueFormatted = value
  } else {
    return -1
  }

  if (isNaN(valueFormatted)) {
    return -1
  }

  valueFormatted = Number(valueFormatted.toFixed(2))
  return valueFormatted
}

export function checkPricePayloadValues(
  price: Package.PlanPackagePriceUnformatted,
): string | Package.PlanPackagePrice {
  const formattedPrice: Package.PlanPackagePrice = {
    grossPrice: formatToNumber(price.grossPrice),
    discounts: {
      freePercentage: formatToNumber(price.discounts.freePercentage),
      mediaAgencyPercentage: formatToNumber(
        price.discounts.mediaAgencyPercentage,
      ),
      otherPercentage: formatToNumber(price.discounts.otherPercentage),
    },
    fees: {
      production: formatToNumber(price.fees.production),
      assetAllocation: formatToNumber(price.fees.assetAllocation),
      materialHandling: formatToNumber(price.fees.materialHandling),
      photography: formatToNumber(price.fees.photography),
    },
    totalNet: 0,
  }

  if (formattedPrice.grossPrice < 0) {
    return 'Invalid gross price'
  }
  if (formattedPrice.discounts.freePercentage < 0) {
    return 'Invalid free percentage'
  }
  if (formattedPrice.discounts.mediaAgencyPercentage < 0) {
    return 'Invalid media agency percentage'
  }
  if (formattedPrice.discounts.otherPercentage < 0) {
    return 'Invalid other percentage'
  }
  if (formattedPrice.fees.production < 0) {
    return 'Invalid production fee'
  }
  if (formattedPrice.fees.assetAllocation < 0) {
    return 'Invalid asset allocation fee'
  }
  if (formattedPrice.fees.materialHandling < 0) {
    return 'Invalid material handling fee'
  }
  if (formattedPrice.fees.photography < 0) {
    return 'Invalid photography fee'
  }

  return formattedPrice
}

// function getPriceWithHumanRepresentation(price: Package.PlanPackagePrice): Package.PlanPackagePriceUnformatted {
//   return {
//     grossPrice: numberFormat(price.grossPrice),
//     discounts: {
//       freePercentage: numberFormat(price.discounts.freePercentage),
//       mediaAgencyPercentage: numberFormat(price.discounts.mediaAgencyPercentage),
//       otherPercentage: numberFormat(price.discounts.otherPercentage),
//     },
//     fees: {
//       production: numberFormat(price.fees.production),
//       assetAllocation: numberFormat(price.fees.assetAllocation),
//       materialHandling: numberFormat(price.fees.materialHandling),
//       photography: numberFormat(price.fees.photography),
//     },
//     totalNet: numberFormat(price.totalNet),
//   }
// }

export function getPriceValueUpdated(
  price: Package.PlanPackagePrice,
): Package.PlanPackagePrice {
  let priceUpdatedWithDiscounts = price.grossPrice
  priceUpdatedWithDiscounts =
    priceUpdatedWithDiscounts -
    priceUpdatedWithDiscounts * price.discounts.freePercentage * 0.01
  priceUpdatedWithDiscounts =
    priceUpdatedWithDiscounts -
    priceUpdatedWithDiscounts * price.discounts.otherPercentage * 0.01
  priceUpdatedWithDiscounts =
    priceUpdatedWithDiscounts -
    priceUpdatedWithDiscounts * price.discounts.mediaAgencyPercentage * 0.01

  const fees =
    price.fees.production +
    price.fees.assetAllocation +
    price.fees.materialHandling
  const totalNet = priceUpdatedWithDiscounts + fees
  const priceUpdated = {
    ...price,
    totalNet,
  }
  return priceUpdated
}
