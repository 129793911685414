import { Poi } from '@workspaces/types'

export function convertPointsToGeoJsonPoint(points: Poi.CustomPOI[]) {
  return {
    type: 'FeatureCollection',
    features: points.map((point) => ({
      type: 'Feature',
      geometry: JSON.parse(point.geom),
      properties: {
        name: point.name,
      },
    })),
  }
}
