import {
  AssetDataGeoJsonLayer,
  AssetDataGeoJsonLayerRegionsRelations,
  AssetFilterInventory,
  AssetId,
  AssetIdIndex,
} from './asset.type'
import { Filter } from './filter.type'
import { FilterAppliedResult } from './plan.type'
import { Environment, Metadata } from '@workspaces/types'
import {
  GeographicalDistributionMaxFilters,
  GeographicalDistributionRegion,
  GeographicalDistributionRegionWithAssets,
} from './geographicalDistribution.type'

import { getAppMetadata } from '../metadata/metadata'
import AssetsService from '@/services/assets.service'

export function getImpressionsForAssets(data: AssetDataGeoJsonLayer[]): number {
  let impressions = 0
  data.forEach((asset) => {
    if (!asset.properties.excluded) {
      impressions += asset.properties.impressions ?? 0
    }
  })
  return impressions
}

export function getAssetDataGeoJsonLayerPropertiesKey(
  key: string,
): keyof AssetDataGeoJsonLayerRegionsRelations {
  return `${key}_id` as keyof AssetDataGeoJsonLayerRegionsRelations
}

export function setExcludedPropertyToAssetsBySubFilter(
  allAssets: AssetDataGeoJsonLayer[],
  assets: AssetDataGeoJsonLayer[],
  excludedIds: string[],
): AssetDataGeoJsonLayer[] {
  const excludedAssets: AssetDataGeoJsonLayer[] = []
  const assetsWithoutExcluded = assets.filter((asset) => {
    const isExcludedAsset = excludedIds.includes(asset.properties.id)
    if (isExcludedAsset) {
      const assetExcluded = {
        ...asset,
        properties: { ...asset.properties, excluded: true },
      }
      excludedAssets.push(assetExcluded)
    }
    return !isExcludedAsset
  })
  const assetsCombined = [...assetsWithoutExcluded, ...excludedAssets]

  let excludedAssetsOutsideFilter = []
  if (excludedAssets.length > 0) {
    excludedAssetsOutsideFilter = excludedIds.filter((id) => {
      return !excludedAssets.find((asset) => asset.properties.id === id)
    })
  } else {
    excludedAssetsOutsideFilter = [...excludedIds]
  }

  const excludedAssetsOutsideFilterWithProperties =
    excludedAssetsOutsideFilter.map((id) => {
      const asset = allAssets.find((asset) => asset.properties.id === id)
      if (!asset) {
        throw new Error(`Asset with id ${id} not found`)
      }
      return {
        ...asset,
        properties: {
          ...asset.properties,
          excluded: true,
          excludedLayer: true,
        },
      }
    })
  const assetsCombinedWithExcludedOutsideFilter = [
    ...assetsCombined,
    ...excludedAssetsOutsideFilterWithProperties,
  ]

  return assetsCombinedWithExcludedOutsideFilter
}

/**
 *
 * @param assets Assets filtered at the moment
 * @param includedIds Assets included by the user that are not taken into account by filter. These assets are included via the Excluded Assets Layer
 * @returns Filtered assets, plus the ones included manually by the user via the map
 */
export function addIncludedAssetsFromExcludeLayer(
  allAssets: AssetDataGeoJsonLayer[],
  assets: AssetDataGeoJsonLayer[],
  includedIds: string[],
): FilterAppliedResult {
  if (!includedIds || includedIds.length === 0) {
    return { assets, filterApplied: false }
  }
  const includedAssets: AssetDataGeoJsonLayer[] = []
  includedIds.forEach((id) => {
    const asset = assets.find((asset) => asset.properties.id === id)
    if (!asset) {
      const asset = allAssets.find((asset) => asset.properties.id === id)
      if (!asset) {
        throw new Error(`Asset with id ${id} not found`)
      }
      includedAssets.push(asset)
    }
  })

  const filteredAssets = [...assets, ...includedAssets]
  return { assets: filteredAssets, filterApplied: true }
}

function filterAssetsByInventoryOnMode(
  assets: AssetDataGeoJsonLayer[],
  filterParam: AssetFilterInventory,
  exclusionMode: boolean,
): AssetDataGeoJsonLayer[] {
  const filteredAssets = assets.filter((asset) => {
    const { properties } = asset
    let value = null
    if (filterParam.field === 'PlantUnitId') {
      value = properties.name?.toString() || null
    } else if (filterParam.field === 'GeopathId') {
      value = properties.external_panel_id?.toString() || null
    } else {
      value = properties.internal_panel_id?.toString() || null
    }

    if (!value) {
      return false
    }
    if (exclusionMode) {
      return !filterParam.ids.includes(value)
    }
    return filterParam.ids.includes(value)
  })
  return filteredAssets
}

function filterAssetsByInventoryIncluded(
  assets: AssetDataGeoJsonLayer[],
  subfilter: Filter,
): FilterAppliedResult {
  const filteredAssets = filterAssetsByInventoryOnMode(
    assets,
    subfilter.panels,
    false,
  )
  return { assets: filteredAssets, filterApplied: true }
}

function fillRegionsWithAssets(
  meta: Metadata.AppMetadata,
  assets: AssetDataGeoJsonLayer[],
  regions: GeographicalDistributionRegion[],
): GeographicalDistributionRegionWithAssets[] {
  const regionsWithAssetsArray: GeographicalDistributionRegionWithAssets[] =
    regions.map((region) => {
      if (region.type_id === 1) {
        const regionNameMeta = meta.countries.find(
          (country) => country.id_legacy === region.id,
        )
        return { ...region, assets: [], id_meta: regionNameMeta?.id || -1 }
      } else {
        return { ...region, assets: [], id_meta: -1 }
      }
    })
  assets.forEach((asset) => {
    const { properties } = asset
    regionsWithAssetsArray.forEach((region) => {
      const regionKey: keyof AssetDataGeoJsonLayerRegionsRelations =
        getAssetDataGeoJsonLayerPropertiesKey(
          region.field_name.toLocaleLowerCase(),
        )
      const assetRegionFieldId = properties[regionKey]
      if (region.type_id === 1) {
        // COUNTRIES
        if (assetRegionFieldId === region.id_meta) {
          region.assets.push(asset)
        }
      } else {
        // OTHER REGIONS
        if (region.id === assetRegionFieldId) {
          region.assets.push(asset)
        }
      }
    })
  })
  return regionsWithAssetsArray
}

function sortRegionsWithAssets(
  regionsWithAssets: GeographicalDistributionRegionWithAssets[],
  subfilter: Filter,
): GeographicalDistributionRegionWithAssets[] {
  if (subfilter.geographical_distribution.optimizeBy === 'impressions') {
    // BY IMPRESSIONS
    regionsWithAssets.forEach((region) => {
      region.assets.sort((a, b) => {
        return b.properties.impressions - a.properties.impressions
      })
    })
  } else {
    // BY AUDIENCE INDEX
    regionsWithAssets.forEach((region) => {
      region.assets.sort((a, b) => {
        return b.properties.audienceIndex - a.properties.audienceIndex
      })
    })
  }
  return regionsWithAssets
}

function sortAssetsByOptimizationType(
  assets: AssetDataGeoJsonLayer[],
  optimizeBy: string,
): AssetDataGeoJsonLayer[] {
  if (optimizeBy === 'impressions') {
    return assets.sort((a, b) => {
      return b.properties.impressions - a.properties.impressions
    })
  } else {
    return assets.sort((a, b) => {
      return b.properties.audienceIndex - a.properties.audienceIndex
    })
  }
}

async function getAssetsByMinimumDistance(
  environment: Environment.EnvironmentResolver,
  assets: AssetDataGeoJsonLayer[],
  distance: number,
  optimizeBy: string,
): Promise<AssetId[]> {
  const metadata = getAppMetadata()
  const chuncksSize = 5000
  // iteration 1
  const sortedAssets = sortAssetsByOptimizationType(assets, optimizeBy)
  const assetsIds = sortedAssets.map((asset) => asset.properties.id)
  const result = await AssetsService.getAssetsByMinimumDistance(
    metadata,
    environment,
    assetsIds,
    distance,
    chuncksSize,
  )
  if (chuncksSize > assets.length) {
    return result
  }
  // iteration 2
  const resultIds2 = [...new Set(result.map((asset) => asset.asset_id))]
  const assets2 = sortedAssets.filter((asset) =>
    resultIds2.includes(asset.properties.id),
  )
  const assetsIds2 = assets2.map((asset) => asset.properties.id)

  const result2 = await AssetsService.getAssetsByMinimumDistance(
    metadata,
    environment,
    assetsIds2,
    distance,
    chuncksSize,
  )
  return result2
}

function truncateRegionsWithAssets(
  meta: Metadata.AppMetadata,
  regionsWithAssets: GeographicalDistributionRegionWithAssets[],
  subfilter: Filter,
): GeographicalDistributionRegionWithAssets[] {
  const maxFilters = subfilter.geographical_distribution.maxFilters
  const regionExceptions = subfilter.geographical_distribution.regionExceptions
  regionsWithAssets.forEach((region) => {
    if (regionExceptions && regionExceptions[region.id] !== undefined) {
      const maxValue = regionExceptions[region.id].value
      // truncate assets by exceptions without distance between assets
      if (!subfilter.geographical_distribution.distanceFilterActive) {
        region.assets = region.assets.slice(0, maxValue)
      }
    } else {
      // truncate assets by max filters
      const regionInfo = Object.entries(
        meta.data_model.regions.regions_hierarchy,
      ).find((r) => r[1].id === region.type_id)
      if (regionInfo) {
        const regionKey =
          regionInfo[0] as keyof GeographicalDistributionMaxFilters
        const filterInfo = maxFilters[regionKey]
        if (filterInfo.active) {
          if (!subfilter.geographical_distribution.distanceFilterActive) {
            region.assets = region.assets.slice(0, filterInfo.max)
          }
        }
      }
    }
  })
  return regionsWithAssets
}

function mergeAllRegionsWithAssets(
  regionsWithAssets: GeographicalDistributionRegionWithAssets[],
): AssetDataGeoJsonLayer[] {
  const assets: AssetDataGeoJsonLayer[] = []
  const allAssets: AssetDataGeoJsonLayer[] = regionsWithAssets.reduce(
    (acc: AssetDataGeoJsonLayer[], region) => {
      return [...acc, ...region.assets]
    },
    [],
  )
  const mapAssets = new Map()
  for (const asset of allAssets) {
    if (!mapAssets.has(asset.properties.id)) {
      mapAssets.set(asset.properties.id, true) // set any value to Map
      assets.push(asset)
    }
  }
  return assets
}

function flatAndUniqueAssetsAudience(
  assetsAudience: AssetIdIndex[][],
): AssetIdIndex[] {
  const assetsAudienceFlat = assetsAudience.flat(2)
  const assetsAudienceUnique: AssetIdIndex[] = []

  const mapAssetAudience = new Map()
  for (const assetAudience of assetsAudienceFlat) {
    if (!mapAssetAudience.has(assetAudience.asset_id)) {
      mapAssetAudience.set(assetAudience.asset_id, true) // set any value to Map
      assetsAudienceUnique.push(assetAudience)
    }
  }

  return assetsAudienceUnique
}

function getTopPriorityRegion(
  meta: Metadata.AppMetadata,
  regionTypes: number[],
): number {
  return regionTypes
    .map((rtype) => {
      const metaRegions = Object.values(
        meta.data_model.regions.regions_hierarchy,
      )
      const order = metaRegions.find((r) => r.id === rtype)?.order || 0
      return order
    })
    .sort()[0]
}

function filterRegionsByPriority(
  meta: Metadata.AppMetadata,
  regions: GeographicalDistributionRegion[],
): GeographicalDistributionRegion[] {
  const types: number[] = [...new Set(regions.map((r) => r.type_id))]
  if (types.length === 1) {
    return regions
  } else {
    const topPriorityRegion = getTopPriorityRegion(meta, types)
    return regions.filter((r) => r.type_id === topPriorityRegion)
  }
}

export async function filterAssetsByOptimizeDistribution(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
  assets: AssetDataGeoJsonLayer[],
  subfilter: Filter,
  regions: GeographicalDistributionRegion[],
  assetsAudience: AssetIdIndex[][],
): Promise<FilterAppliedResult> {
  // check if geographical distribution filter is active
  if (
    !subfilter.geographical_distribution ||
    (subfilter.geographical_distribution.maxFilterActive === false &&
      subfilter.geographical_distribution.distanceFilterActive === false)
  ) {
    return { assets, filterApplied: false }
  }
  // check active regions in geographical distribution filter
  if (
    regions.length === 0 &&
    subfilter.geographical_distribution.distanceFilterActive === false
  ) {
    return { assets, filterApplied: false }
  }

  let filteredAssets: AssetDataGeoJsonLayer[] = []

  // OPTIMIZE MAXIMUM NUMBER OF ASSETS BY REGION
  if (subfilter.geographical_distribution.maxFilterActive) {
    // prepare assets with audience index
    if (subfilter.geographical_distribution.optimizeBy === 'audience') {
      // merge assets with audience indexes
      const mergedAssetsAudience = flatAndUniqueAssetsAudience(assetsAudience)
      // populate assets with audience index
      if (mergedAssetsAudience.length) {
        assets.forEach((asset) => {
          const assetAudience = mergedAssetsAudience.find(
            (assetAudience) => assetAudience.asset_id === asset.properties.id,
          )
          if (assetAudience) {
            asset.properties.audienceIndex = assetAudience.index
          }
        })
      }
    }
    // filters regions by region priority
    const regionsFiltered = filterRegionsByPriority(meta, regions)
    // filters assets by regions
    const regionsWithAssets = fillRegionsWithAssets(
      meta,
      assets,
      regionsFiltered,
    )
    // sort assets by impressions or audience index
    sortRegionsWithAssets(regionsWithAssets, subfilter)
    // truncate each region assets by max filters
    truncateRegionsWithAssets(meta, regionsWithAssets, subfilter)
    // merge assets
    filteredAssets = mergeAllRegionsWithAssets(regionsWithAssets)
  }

  // ONLY DISTANCE BETWEEN ASSETS
  if (
    subfilter.geographical_distribution.distanceFilterActive &&
    !subfilter.geographical_distribution.maxFilterActive
  ) {
    const distance = subfilter.geographical_distribution.distanceFilter
    const optimizeBy = subfilter.geographical_distribution.optimizeBy
    const assetsIdsObj = await getAssetsByMinimumDistance(
      environment,
      assets,
      distance,
      optimizeBy,
    )
    const assetsIds = assetsIdsObj.map((asset) => asset.asset_id)
    filteredAssets = assets.filter((asset) =>
      assetsIds.includes(asset.properties.id),
    )
  }

  return { assets: filteredAssets, filterApplied: true }
}

export function filterAssetsByInventory(
  allAssets: AssetDataGeoJsonLayer[],
  assets: AssetDataGeoJsonLayer[],
  subFilter: Filter,
): FilterAppliedResult {
  let filterApplied = false

  const inventoryIncludedPartialResult: FilterAppliedResult =
    filterAssetsByInventoryIncluded(assets, subFilter)
  filterApplied = inventoryIncludedPartialResult.filterApplied || filterApplied

  return { assets: inventoryIncludedPartialResult.assets, filterApplied }
}

export function filterAssetsByInventoryOverwrittingAssets(
  allAssets: AssetDataGeoJsonLayer[],
  subFilter: Filter,
): FilterAppliedResult {
  let filterApplied = false

  const inventoryIncludedPartialResult: FilterAppliedResult =
    filterAssetsByInventoryIncluded(allAssets, subFilter)
  filterApplied = inventoryIncludedPartialResult.filterApplied || filterApplied

  return { assets: inventoryIncludedPartialResult.assets, filterApplied }
}
