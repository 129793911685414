import {
  FilterByPoisParamsRequestData,
  PoisFilter,
} from '../plan/filter/pois.type'
import layerService from '@/layers/layerService'
import { POI_LAYER, CUSTOM_POI_LAYER } from '@/layers'
import { FetchLayerDataResult } from '@deck.gl/carto/typed/api/maps-v3-client'
import { getAppMetadata } from '../plan/metadata/metadata'
import PoiService from '@/services/poi-browser.service'
import { getCustomPOIsFromDataRequest } from '../plan/filter/pois.helper'
import { commit } from '@/utils/vuex'
import { MODULE_NAME_PLAN } from '@/store/plan'
import { MUTATIONS } from '@/store/plan/constants'
import BrowserEnvironmentResolver from './environment.helper'

export function managePoisLayerVisibility(filter: PoisFilter): void {
  if (filter && filter.data.length > 0) {
    const poiLayer = layerService.getLayer(POI_LAYER)
    if (!poiLayer.props.visible) {
      if (filter.method === 'RADIUS') {
        layerService.showLayer(POI_LAYER, { visibleBuffer: true })
        layerService.showLayer(CUSTOM_POI_LAYER, { visibleBuffer: true })
      } else {
        layerService.showLayer(POI_LAYER, { visibleBuffer: false })
        layerService.showLayer(CUSTOM_POI_LAYER, { visibleBuffer: false })
      }
    }
  } else {
    layerService.hideLayer(POI_LAYER)
  }
}

export async function getCustomPOIsGroupsPoints(
  proximityParams: FilterByPoisParamsRequestData[],
): Promise<FetchLayerDataResult | null> {
  const customPOIsGroupsIds = getCustomPOIsFromDataRequest(proximityParams)
  if (customPOIsGroupsIds.length === 0) {
    return null
  }

  const metadata = getAppMetadata()
  const data = await PoiService.fetchLayer(
    metadata,
    BrowserEnvironmentResolver.getInstance(),
    customPOIsGroupsIds,
  )
  return data
}

export async function updateStateWithPOIsFilter(
  categories: string[],
  brands: string[],
  locations: string[],
  tags: string[],
): Promise<void> {
  commit(MODULE_NAME_PLAN, MUTATIONS.UPDATE_PROXIMITY_DATA, {
    categories,
    brands,
    locations,
    tags,
  })
}
