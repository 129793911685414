import { Filter } from './filter.type'
import {
  GeographicalDistributionRegion,
  GeoboundariesByTypeAndCountryRequest,
  GeoboundariesByTypeAndCountryResult,
} from './geographicalDistribution.type'
import { Environment, Metadata } from '@workspaces/types'
import GeometryService from '@/services/geoboundary.service'

export function hasOptimizeDistributionFilter(filters: Filter[]): boolean {
  const firstSubFilter = filters[0]
  if (firstSubFilter.geographical_distribution) {
    // check if any region is active
    const activeRegions = Object.values(
      firstSubFilter.geographical_distribution.maxFilters,
    ).filter((region) => region.active)
    if (activeRegions.length > 0) {
      return true
    }
    // check if distance filter is active
    if (firstSubFilter.geographical_distribution.distanceFilterActive) {
      return true
    }
  }
  return false
}

export async function getOptimizeDistributionRegions(
  metadata: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
  filters: Filter[],
): Promise<GeographicalDistributionRegion[]> {
  const filter: Filter = filters[0]
  const metadataRegionInfo: Metadata.RegionsHierarchy =
    metadata.data_model.regions.regions_hierarchy
  // prepare base params
  const filterCountries = filter.countries
  const filterGeographicalDist = filter.geographical_distribution
  const countryISO2Ids = metadata.countries
    .filter((country) => filterCountries.includes(country.id))
    .map((country) => country.iso2)
  const typeRegionIds = Object.entries(
    filterGeographicalDist.maxFilters,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ).filter(([key, region]) => region.active)
  const typeRegionInternalIds: number[] = typeRegionIds
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .map(([key, region]) => {
      const info = metadataRegionInfo[key as keyof Metadata.RegionsHierarchy]
      if (!info) {
        return -1
      }
      return Number(info.id)
    })
    .filter((id) => id !== -1)
  // fetch regions
  let geographicalDistributionRegionsResult: GeoboundariesByTypeAndCountryResult[] =
    []
  if (typeRegionInternalIds.length) {
    const queryParams: GeoboundariesByTypeAndCountryRequest = {
      type_id: typeRegionInternalIds,
      country_iso2: countryISO2Ids,
    }

    geographicalDistributionRegionsResult =
      await GeometryService.getGeoboundariesByTypeAndCountry(
        metadata,
        environment,
        queryParams,
      )
  }
  // prepare result
  const regionFieldNames = Object.values(metadataRegionInfo).reduce(
    (acc, region) => {
      acc[region.id] = region.name
      return acc
    },
    {},
  )
  const geographicalDistributionRegions: GeographicalDistributionRegion[] =
    geographicalDistributionRegionsResult.map((region) => {
      return {
        ...region,
        field_name: regionFieldNames[region.type_id],
      }
    })
  return geographicalDistributionRegions || []
}
