import { GETTERS_PLAN, MODULE_NAME_PLAN } from '@/store/plan'
import { Poi } from './pois-layer.types'
import { getters } from '@/utils/vuex'
import { milesToMeter } from '@/utils/metersToPixels'
import { getAppMetadata } from '@/plan/metadata/metadata'
import { Color } from '@deck.gl/core/typed'
import {
  getPOIsColors,
  getPOIsDefaultColor,
} from '@/plan/metadata/metadata.helper'

export function getPOIsRampColorsAsRGB(): Color[] {
  const { ramp } = getPOIsColors(getAppMetadata())
  const rampAsRGB = ramp.map((color) => hexToRGBA(color))
  return rampAsRGB
}

export const POIsDefaultColor = hexToRGBA(getPOIsDefaultColor(getAppMetadata()))
export const POIsOthersColor = hexToRGBA(getPOIsColors(getAppMetadata()).others)
export const POIsRampColor = getPOIsRampColorsAsRGB()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function filterPois(poi: any): number {
  const plan = getters(MODULE_NAME_PLAN, GETTERS_PLAN.GET_PLAN)
  const properties: Poi = poi.properties
  const categoriesFromFilter: string[] = plan.proximity.categories
  const brandsFromFilter: string[] = plan.proximity.brands
  const locationsFromFilter: string[] = plan.proximity.locations
  const tagsFromFilter: string[] = plan.proximity.tags
  if (
    categoriesFromFilter.length === 0 &&
    brandsFromFilter.length === 0 &&
    locationsFromFilter.length === 0 &&
    tagsFromFilter.length === 0
  ) {
    return 0
  }

  if (brandsFromFilter.includes(properties.brands)) {
    return 1
  }

  if (locationsFromFilter.includes(properties.location_name)) {
    return 1
  }
  if (properties.category_tags) {
    const tags = properties.category_tags.split(',')
    if (tagsFromFilter.some((tag) => tags.includes(tag))) {
      return 1
    }
  }

  if (properties.sub_category === null) {
    if (categoriesFromFilter.includes(properties.top_category)) {
      return 1
    }
  } else {
    if (categoriesFromFilter.includes(properties.sub_category)) {
      return 1
    }
  }
  return 0
}

export function getBufferRangeDistanceForPOIs(internal = false): number {
  const filters = getters(MODULE_NAME_PLAN, GETTERS_PLAN.GET_PLAN).filters
  const units = getAppMetadata().units.distance
  const distance = internal
    ? filters[0].proximity.min_distance
    : filters[0].proximity.max_distance

  const bufferRadius = units === 'miles' ? milesToMeter(distance) : distance

  return bufferRadius
}

export function hexToRGBA(hex: string): Color {
  const bigint = parseInt(hex.slice(1), 16)
  return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255, 255]
}

export function rgbaToHex(rgba: Color): string {
  let r = rgba[0].toString(16)
  let g = rgba[1].toString(16)
  let b = rgba[2].toString(16)
  let a = (rgba[3] ?? 255).toString(16)

  if (r.length === 1) {
    r = '0' + r
  }
  if (g.length === 1) {
    g = '0' + g
  }
  if (b.length === 1) {
    b = '0' + b
  }
  if (a.length === 1) {
    a = '0' + a
  }

  return '#' + r + g + b + a
}

interface LayerPOI {
  brands?: string
  category_tags?: string
  location_name?: string
  top_category?: string
  sub_category?: string
}

export function getColorFromRamp(
  properties: LayerPOI | null,
  poisColors: Record<string, Color>,
): Color {
  if (!properties) {
    return POIsDefaultColor
  }
  if (properties.brands && poisColors[properties.brands]) {
    return poisColors[properties.brands]
  }
  if (properties.category_tags && poisColors[properties.category_tags]) {
    return poisColors[properties.category_tags]
  }
  if (properties.location_name && poisColors[properties.location_name]) {
    return poisColors[properties.location_name]
  }
  if (properties.top_category && poisColors[properties.top_category]) {
    return poisColors[properties.top_category]
  }
  if (properties.sub_category && poisColors[properties.sub_category]) {
    return poisColors[properties.sub_category]
  }
  return POIsDefaultColor
}
