import { InsightTemplateUS } from './us/insight.constant'
import {
  Metadata,
  Audience,
  Media,
  Proximity,
  Export,
  Package,
  Auth,
} from '@workspaces/types'

export const METADATA: Metadata.AppMetadata = {
  app_title: 'Clear Channel Outdoor',
  logo: 'logo-radarview-big.svg',
  flavour: 'US',
  flavour_testing: 'Test_US',
  app_config: {
    carto_client_id: {
      dev: 'MiM3uwPAapvN31J9DPp3K0sfaTMWtZt5',
      staging: 'mzsfT4Llz07dc28kOHasoOpVNONnTJSL',
      prod: 'hVtuxZvBApTjp1WUOiHkTl9QXJHXCHq3',
    },
    carto_organization: 'org_nnQ8fYsFblSLqZ79',
    front_url: [
      'https://radarview-us.carto.solutions/.*',
      'https://radarview-us-dev.carto.solutions/.*',
      'https://radarview-us-staging.carto.solutions/.*',
      'https://carto-ps-radarview-c3-dev-.*.web.app/.*',
      'https://carto-ps-radarview-c3-stg-.*.web.app/.*',
      'https://carto-ps-radarview-c3-pro-.*.web.app/.*',
    ],
    firebase: {
      dev: {
        apiKey: 'AIzaSyAoXxEVPqk_dsRkGTViS-NH9IS8Obu_7as',
        authDomain: 'carto-ps-radarview-c3-dev.firebaseapp.com',
        projectId: 'carto-ps-radarview-c3-dev',
        storageBucket: 'carto-ps-radarview-c3-dev.appspot.com',
        messagingSenderId: '663610365249',
        appId: '1:663610365249:web:47292b81627986d7627dc0',
      },
      staging: {
        apiKey: 'AIzaSyDpR2K-roCri9HBoY6l5t42hySNXqzRCck',
        authDomain: 'carto-ps-radarview-c3-stg.firebaseapp.com',
        projectId: 'carto-ps-radarview-c3-stg',
        storageBucket: 'carto-ps-radarview-c3-stg.appspot.com',
        messagingSenderId: '487139862654',
        appId: '1:487139862654:web:0a993e011884522324902f',
      },
      prod: {
        apiKey: 'AIzaSyAuoN9aneLK9E4QVTh4-zZ2eJCSjKbwpxY',
        authDomain: 'carto-ps-radarview-c3-pro.firebaseapp.com',
        projectId: 'carto-ps-radarview-c3-pro',
        storageBucket: 'carto-ps-radarview-c3-pro.appspot.com',
        messagingSenderId: '914630006852',
        appId: '1:914630006852:web:80094e2adc41de4cf70411',
      },
    },
    remote_functions: {
      firebase_token: {
        dev: 'https://us-east1-carto-ps-radarview-c3-dev.cloudfunctions.net/default-function',
        staging:
          'https://us-east1-carto-ps-radarview-c3-stg.cloudfunctions.net/default-function',
        prod: 'https://us-east1-carto-ps-radarview-c3-pro.cloudfunctions.net/default-function',
        local:
          'http://127.0.0.1:5001/carto-ps-radarview-c3-dev/us-central1/firebasToken',
      },
      audit: {
        dev: 'https://us-east1-carto-ps-radarview-c3-dev.cloudfunctions.net/reports',
        staging:
          'https://us-east1-carto-ps-radarview-c3-stg.cloudfunctions.net/reports',
        prod: 'https://us-east1-carto-ps-radarview-c3-pro.cloudfunctions.net/reports',
        local:
          'http://127.0.0.1:5001/carto-ps-radarview-c3-dev/us-central1/reports',
      },
      big_query_custom_POIS: {
        dev: 'https://us-east1-carto-ps-radarview-c3-dev.cloudfunctions.net/custom-pois',
        staging:
          'https://us-east1-carto-ps-radarview-c3-stg.cloudfunctions.net/custom-pois',
        prod: 'https://us-east1-carto-ps-radarview-c3-pro.cloudfunctions.net/custom-pois',
        local:
          'http://127.0.0.1:5001/carto-ps-radarview-c3-dev/us-central1/customPois',
      },
      aida: {
        dev: 'not available',
        staging: 'not available',
        prod: 'not available',
      },
      share_plan: {
        dev: 'https://us-east1-carto-ps-radarview-c3-dev.cloudfunctions.net/plan',
        staging:
          'https://us-east1-carto-ps-radarview-c3-stg.cloudfunctions.net/plan',
        prod: 'https://us-east1-carto-ps-radarview-c3-pro.cloudfunctions.net/plan',
      },
      isochrones: {
        dev: 'not available',
        staging: 'not available',
        prod: 'not available',
      },
      custom_geoboundaries: {
        dev: 'https://europe-west1-carto-ps-radarview-c3-dev.cloudfunctions.net/custom-geoboundaries',
        staging:
          'https://europe-west1-carto-ps-radarview-c3-stg.cloudfunctions.net/custom-geoboundaries',
        prod: 'https://europe-west1-carto-ps-radarview-c3-pro.cloudfunctions.net/custom-geoboundaries',
      },
    },
  },
  roles: [],
  bq_project: {
    dev: 'carto-dw-ac-sa17sfhv',
    staging: 'carto-dw-ac-sa17sfhv',
    prod: 'carto-dw-ac-sa17sfhv',
  },
  carto_events_url: 'https://workspace-gcp-us-east1.app.carto.com/events',
  base_url: 'https://gcp-us-east1.api.carto.com',
  auth0_user_key: 'http://app.carto.com/email',
  auth0_account_id_key: 'http://app.carto.com/account_id',
  connection: 'radarview_us',
  analytics_toolbox_base_url: 'carto-un',
  languages: {
    enable: false,
    options: [
      { id: 'en', name: 'ENGLISH', locale: 'en-us' },
      { id: 'es', name: 'SPANISH' },
      { id: 'fr', name: 'FRENCH' },
      { id: 'it', name: 'ITALIAN' },
    ],
  },
  countries: [
    {
      id: 181,
      name: 'United States',
      iso2: 'US',
      iso3: 'USA',
    },
  ],
  local_storage_keys: {
    lang: 'CCO_lang',
    auth: 'CCO_auth',
    firebase_token: 'CCO_firebaseToken',
  },
  units: {
    distance: 'miles',
    currency: 'USD',
    currency_symbol: '$',
  },
  data_model: {
    regions: {
      table_name: 'regions',
      regions_hierarchy: {
        region_1: { id: 1, name: 'state', order: 1, countries: [] },
        region_2: { id: 2, name: 'dmas', order: 2, countries: [] },
        region_3: {
          id: 3,
          name: 'congresional_district',
          order: 3,
          countries: [],
        },
        region_4: { id: 4, name: 'cbsa', order: 4, countries: [] },
        region_5: { id: 5, name: 'county', order: 5, countries: [] },
        region_6: { id: 6, name: 'city', order: 6, countries: [] },
        region_7: { id: 7, name: 'zip_code', order: 7, countries: [] },
        region_8: { id: 8, name: 'market', order: 8, countries: [] },
        region_15: { id: 15, name: 'file', order: 9, countries: [] },
      },
      partitioned_column: 'dma',
      columns: [
        {
          id: 'id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          autocomplete: true,
          display: false,
        },
        {
          id: 'name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          autocomplete: true,
          display: false,
        },
        {
          id: 'normalized_name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          autocomplete: false,
          display: false,
        },
        {
          id: 'type',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          autocomplete: true,
          display: false,
        },
        {
          id: 'geom',
          type: Metadata.DataModelFieldTypes.Geography,
          visible: false,
          filterable: false,
          autocomplete: false,
          display: false,
        },
        {
          id: 'country_id',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          autocomplete: false,
          display: false,
        },
        {
          id: 'iso2',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          autocomplete: false,
          display: false,
        },
        {
          id: 'iso3',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          autocomplete: false,
          display: false,
        },
        {
          id: 'parent',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          autocomplete: false,
          display: false,
        },
      ],
    },
    assets: {
      cache: {
        dbName: 'Radarview_US',
        store: 'assetsStore',
        key: 'assets',
        key_last_update: 'lastUpdate',
        version: 1,
      },
      chunk_size: 20500,
      table_name: 'assets_regions',
      cluster_icon: 'cluster',
      columns: [
        {
          id: 'address',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: true,
          background: true,
        },
        {
          id: 'ad_region_text',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: false,
          background: true,
        },
        {
          id: 'external_panel_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'geom',
          type: Metadata.DataModelFieldTypes.Geography,
          visible: false,
          filterable: true,
          display: false,
          startup: false,
        },
        {
          id: 'id',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
          background: true,
        },
        {
          id: 'internal_panel_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'impressions',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'is_digital',
          type: Metadata.DataModelFieldTypes.Boolean,
          visible: false,
          filterable: false,
          display: true,
          startup: false,
        },
        {
          id: 'latitude',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'longitude',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'size',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: true,
          background: true,
        },
        {
          id: 'title',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: false,
        },
        {
          id: 'l1_panel_class',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: true,
          background: true,
        },
        {
          id: 'l1panelclass_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'l2product_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'l2_product',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: true,
          background: true,
        },
        {
          id: 'state_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'dmas_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'dmas_name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: true,
          background: false,
        },
        {
          id: 'congresional_district_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'congresional_district_name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: true,
          background: false,
        },
        {
          id: 'cbsa_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'cbsa_name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: true,
          background: false,
        },
        {
          id: 'county_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'county_name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: true,
          background: false,
        },
        {
          id: 'city_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'city_name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: true,
          background: false,
        },
        {
          id: 'zip_code_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'zip_code_name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'postal_code_text',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: false,
        },
        {
          id: 'facing',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: true,
          background: true,
        },
        {
          id: 'co_located',
          type: Metadata.DataModelFieldTypes.Boolean,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'business_unit_id',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: true,
        },
        {
          id: 'package_id',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: false,
        },
        {
          id: 'l3subproduct_id',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: false,
        },
        {
          id: 'city_text',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: false,
        },
        {
          id: 'pictureurl',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: true,
          background: true,
        },
        {
          id: 'fulltext',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: false,
        },
        {
          id: 'l4_id',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: false,
        },
        {
          id: 'l5_id',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: false,
        },
        {
          id: 'l6_id',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: false,
        },
        {
          id: 'l7_id',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: false,
        },
        {
          id: 'l8_id',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: false,
        },
        {
          id: 'l9_id',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: false,
        },
        {
          id: 'state_name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: true,
          background: true,
        },
        {
          id: 'l2_product',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: true,
          background: true,
        },
        {
          id: 'business_unit_id',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: false,
        },
        {
          id: 'market_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'market',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: true,
          background: true,
        },
        {
          id: 'title',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: false,
          background: true,
        },
        {
          id: 'description',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: true,
          background: false,
        },
      ],
    },
    custom_pois: {
      table_name: 'custom_pois',
      columns: [
        {
          id: 'id',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'pois',
          type: Metadata.DataModelFieldTypes.Array,
          visible: false,
          filterable: false,
          display: false,
        },
        {
          id: 'ishared',
          type: Metadata.DataModelFieldTypes.Boolean,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'user_email',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'top_category',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'sub_category',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: false,
        },
      ],
    },
    pois: {
      tileset: 'pois_within_10miles_regions_tileset',
      table_name: 'pois_within_10miles_regions',
      autocomplete: 'pois_all_location_brands_category',
      autocomplete_locations: 'pois_autocomplete',
      columns: [
        {
          id: 'id',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'location_name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'top_category',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'sub_category',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'naics_code',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'geom',
          type: Metadata.DataModelFieldTypes.Geography,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'street_address',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'category_tags',
          type: Metadata.DataModelFieldTypes.Array,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'state_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'dmas_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'congressional_distric_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'cbsa_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'county_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'city_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'zip_code_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
      ],
    },
    audiences: {
      table_name: 'audiences',
      columns: [
        {
          id: 'id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'year',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'month',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'panel',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'market',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'index',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'asset_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'clazz_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'county_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'month_unique_counts',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'month_contact_counts',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
      ],
    },
    audiences_classes: {
      table_name: 'audiences_classes',
      columns: [
        {
          id: 'year_month_or_week',
          type: Metadata.DataModelFieldTypes.Date,
          visible: true,
          filterable: true,
          display: false,
        },
        {
          id: 'country_iso3',
          type: Metadata.DataModelFieldTypes.String,
          visible: true,
          filterable: false,
          display: false,
        },
        {
          id: 'uid',
          type: Metadata.DataModelFieldTypes.String,
          visible: true,
          filterable: true,
          display: false,
        },
        {
          id: 'class_group',
          type: Metadata.DataModelFieldTypes.String,
          visible: true,
          filterable: true,
          display: false,
        },
        {
          id: 'audience',
          type: Metadata.DataModelFieldTypes.String,
          visible: true,
          filterable: true,
          display: false,
        },
        {
          id: 'attr1',
          type: Metadata.DataModelFieldTypes.String,
          visible: true,
          filterable: true,
          display: false,
        },
        {
          id: 'attr2',
          type: Metadata.DataModelFieldTypes.String,
          visible: true,
          filterable: true,
          display: false,
        },
        {
          id: 'attr3',
          type: Metadata.DataModelFieldTypes.String,
          visible: true,
          filterable: true,
          display: false,
        },
        {
          id: 'attr4',
          type: Metadata.DataModelFieldTypes.String,
          visible: true,
          filterable: true,
          display: false,
        },
        {
          id: 'attr5',
          type: Metadata.DataModelFieldTypes.String,
          visible: true,
          filterable: true,
          display: false,
        },
        {
          id: 'attr6',
          type: Metadata.DataModelFieldTypes.String,
          visible: true,
          filterable: true,
          display: false,
        },
        {
          id: 'name',
          type: Metadata.DataModelFieldTypes.String,
          visible: true,
          filterable: true,
          display: false,
        },
        {
          id: 'type',
          type: Metadata.DataModelFieldTypes.Number,
          visible: true,
          filterable: true,
          display: false,
        },
      ],
    },
    panelcounts: {
      table_name: 'panelcounts',
      columns: [],
    },
    pois_isochrones: {
      table_name: 'pois_isochrones',
      columns: [],
    },
    shared_plans_assets: {
      table_name: 'shared_plan_results',
      columns: [],
    },
    custom_geoboundaries: {
      table_name: 'custom_geoboundaries',
      columns: [],
    },
    packages: {
      table_name: 'packages',
      columns: [],
    },
    packages_availability: {
      table_name: 'packages_availability',
      columns: [],
    },
  },
  features: {
    permission_resolver: Auth.PermissionResolver.US,
    comments: {
      enable: false,
      attachments: {
        enable: false,
        max_size_mb: 20,
      },
    },
    custom_geoboundaries: {
      enable: false,
      upload_file: false,
      upload_file_max_size_mb: 1,
      save_group_from_plan: false,
    },
    packages: {
      enable: false,
      expiration: {
        booking_confirmation: 48 * 60 * 60 * 1000,
        cancellation_time_limit: 48 * 60 * 60 * 1000,
      },
      availability_range: [
        {
          value: 90,
          text: 'HIGH_RANGE',
          color: '#76cc85',
          description: 'Campaign can most likely be delivered',
        },
        {
          value: 40,
          text: 'MEDIUM_RANGE',
          color: '#f0a65e',
          description:
            'Campaign can most likely be delivered with small adjustments',
        },
        {
          value: 0,
          text: 'LOW_RANGE',
          color: '#fb837d',
          description: 'High demand',
        },
      ],
      states_style: {
        [Package.State.Draft]: { background: '#c9cbd1', foreground: '#383d4c' },
        [Package.State.BookingRequest]: {
          background: '#fbcf7d',
          foreground: '#383d4c',
        },
        [Package.State.BookingConfirmedByAdmin]: {
          background: '#fbcf7d',
          foreground: '#383d4c',
        },
        [Package.State.BookingConfirmedByAgency]: {
          background: '#76cc85',
          foreground: '#ffffff',
        },
        [Package.State.ReBooking]: {
          background: '#fbcf7d',
          foreground: '#383d4c',
        },
        [Package.State.Expired]: {
          background: '#fb837d',
          foreground: '#ffffff',
        },
        [Package.State.Booked]: {
          background: '#296f34',
          foreground: '#ffffff',
        },
        [Package.State.Cancelled]: {
          background: '#cb433c',
          foreground: '#ffffff',
        },
        [Package.State.Rejected]: {
          background: '#81413e',
          foreground: '#ffffff',
        },
      },
      dashboard_columns: {
        locale_keys: [
          'TITLE',
          'DATE_RANGE',
          'STATUS',
          'PANELS',
          'PRICE',
          'AVAILABILTY',
          'IMPRESSIONS',
          'USERS',
          'LAST_UPDATE',
        ],
        model_accessors: [
          'title',
          'date_range',
          'state',
          'panels',
          'price',
          'availability',
          'impressions',
          'users',
          'last_update',
        ],
      },
    },
    plan: {
      dashboard_columns: {
        locale_keys: [
          'TITLE',
          'PANELS',
          'IMPRESSIONS',
          'USER',
          'DATE_MODIFIED',
        ],
        model_accessors: [
          'name',
          'count',
          'impressions',
          'created',
          'modified',
        ],
      },
      enable_comments: false,
      save_brand: false,
      save_client: false,
    },
    assets: {
      exclude: true,
      skip_popup_and_go_to_sidebar: true,
      show_sidebar_when_double_click_on_list: true,
    },
    widgets: {
      show_button_tooltip_as_copies: true,
    },
    proximity: {
      max_zip_codes_per_file: 1000,
      multicolor: false,
      ramp_color: [
        '#5F4690',
        '#1D6996',
        '#38A6A5',
        '#0F8554',
        '#73AF48',
        '#EDAD08',
        '#E17C05',
        '#CC503E',
        '#94346E',
        '#6F4070',
        '#994E95',
      ],
      others_color: '#666666',
      default_color: '#b08527',
    },
    map: {
      asset_details_over_map: false,
      fixed_cluster_size: 1.75,
      zoom_hide_cluster: 6,
      max_bbox: [-168, 3, -56, 53],
      view_state: {
        longitude: -89.733208,
        latitude: 40.514933,
        zoom: 3,
        bearing: 0,
        pitch: 0,
      },
      legend: {
        colors: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 16, 17, 18, 19, 20,
        ],
        enable_product_selection: false,
        default_product_selection: 'l2product_id',
        exclude_assets: true,
        facing_direction: true,
        show_geoboundaries_layer: true,
        show_pois_layer: true,
        show_product_selection: true,
        title: 'LAYERS',
      },
      highlightSelectedMarker: true,
      screenshot_btn: false,
    },
    audit: {
      enable: true,
    },
    dashboard: {
      show_others_groups_tab: false,
      show_public_plans_by_country_and_user: false,
    },
    share: {
      enable: {
        dev: false,
        staging: false,
        prod: false,
      },
      retrieve_in_blocks_of: 20000,
      expiracyOffset: 60 * 60 * 24 * 365 * 1000,
      password: true,
    },
    new_plan: {
      enable: false,
    },
    save_plan: {
      enable_save_as: true,
      enable: true,
    },
    export: {
      enable: true,
      include_total_assets_column: false,
      add_tab_xlsx_pois: false,
      fileColumnTitles: [
        Export.FilePOIColumns.Name,
        Export.FilePOIColumns.Distance,
        Export.FilePOIColumns.Address,
        Export.FilePOIColumns.ZipCode,
      ],
      columnsPOIs: [
        'p.location_name as name',
        'p.geom',
        'p.street_address address',
        'p.postal_code zip_code',
      ],
      columnsAllPOIs: [
        'name',
        'geom',
        'COALESCE(address, " ") as address',
        'COALESCE(CAST(zip_code AS String), " ") as zip_code',
      ],
      columnsCustomPOIs: [
        'name',
        'geom',
        'COALESCE(address, " ") as address',
        '"" as zip_code',
      ],
      fields_mapping: [
        {
          model: 'assets',
          modelField: 'market',
          exportField: 'Market',
        },
        { model: 'assets', modelField: 'name', exportField: 'Plant Unit ID' },
        {
          model: 'assets',
          modelField: 'external_panel_id',
          exportField: 'Geopath ID',
        },
        {
          model: 'assets',
          modelField: 'internal_panel_id',
          exportField: 'Billboard Number',
        },
        {
          model: 'assets',
          modelField: 'l1_panel_class',
          exportField: 'Product Name',
        },
        {
          model: 'assets',
          modelField: 'impressions',
          exportField: 'Weekly Impressions',
        },
        { model: 'assets', modelField: 'latitude', exportField: 'Latitude' },
        { model: 'assets', modelField: 'longitude', exportField: 'Longitude' },
        {
          model: 'assets',
          modelField: 'zip_code_name',
          exportField: 'Zip Code',
        },
        {
          model: 'assets',
          modelField: 'title',
          exportField: 'Panel Description',
        },
      ],
      fields: ['a', 'b', 'c', 'd', 'e', 'f'], // not integrated yet
      fields_shared_plan: ['a', 'b', 'f'], // not integrated yet
    },
    insights: {
      enable: true,
      detail: {
        id_field: 'id',
        second_id_field: null,
        show_image: false,
        show_advanced_address: false,
        show_media_type: false,
        show_audience: false,
      },
      tabs: {
        impressions: false,
        demographics: true,
        top_indexing: true,
        table_view: true,
      },
      features: {
        demographics: {
          simpliest_chart: 'histogram',
          pre_filter_audiences_classes: true, // If true, template must be provided
          template: InsightTemplateUS,
          sort_by_values: false,
          identify_gender_charts: ['gender', 'individual-gender'],
          format: {
            index: (value: number) => {
              return value
            },
            panel: (value: number) => {
              return value * 100
            },
            market: (value: number) => {
              return value * 100
            },
          },
        },
        table_view: {
          name: true,
          market: false,
          panel: false,
          index: true,
        },
      },
    },
    manage_pois: {
      enable: true,
      types: {
        coords: true,
        geocoding: false,
      },
      default_type: 'coords',
      distinguish_between_user_and_admin: false,
      max_custom_pois_per_file: 1000,
      max_custom_pois_geocoding: 500,
      max_custom_pois_per_file_admin_user: 1000,
      max_custom_pois_geocoding_admin_user: 500,
      template_file_lonlat: 'us_template_custom_pois.xlsx',
      template_file_address: 'us_template_custom_pois_addresses.xlsx',
    },
    popup: {
      title_i18n: 'externalpanelid',
      title_id_field: 'external_panel_id',
      subtitle_id_field: '',
      show_picture: true,
      show_address: true,
      show_coordinates: true,
      show_facing_direction: true,
      show_streetview: true,
      show_panel_name: true,
      show_type: true,
      show_impressions: true,
      show_audiences: true,
      show_exclude: true,
      show_details: true,
      shared_plan_show_details: false,
      show_isoline: false,
      show_description: true,
    },
    popup_minimal: {
      title_i18n: 'externalpanelid',
      title_id_field: 'external_panel_id',
      subtitle_id_field: '',
      show_picture: true,
      show_address: true,
      show_coordinates: true,
      show_facing_direction: true,
      show_streetview: true,
      show_panel_name: false,
      show_type: false,
      show_impressions: false,
      show_audiences: false,
      show_exclude: true,
      show_details: false,
      shared_plan_show_details: false,
      show_isoline: false,
    },
    popup_share: {
      title_i18n: 'externalpanelid',
      title_id_field: 'external_panel_id',
      subtitle_id_field: '',
      show_picture: true,
      show_address: true,
      show_coordinates: true,
      show_facing_direction: true,
      show_streetview: true,
      show_panel_name: false,
      show_type: true,
      show_impressions: true,
      show_audiences: false,
      show_exclude: false,
      show_details: false,
      shared_plan_show_details: false,
      show_isoline: false,
    },
    help: {
      enable: true,
      url: 'https://clearchanneloutdoor.atlassian.net/servicedesk/customer/portal/6',
    },
    multiTabsFilters: {
      enable: false,
      max_tabs: 2,
    },
  },
  plan_filters: {
    widget_countries: {
      enable: false,
      default_selection_iso3: ['USA'],
    },
    widget_assets: {
      enable: true,
      enable_only_admin: false,
      xlsx_column_name: 'display_id',
      example_file: 'us_template_panel_ids.xlsx',
      upload_by_file: true,
      upload_by_paste: true,
      default_option: 'file',
      upload_inventory_permit_excluded: true,
    },
    widget_geoboundaries: {
      enable: true,
      permit_exclude: true,
      custom_drawing: {
        enable: true,
        polygon: true,
        circle: true,
        route: true,
      },
      sample_files: [{ name: 'US', link: 'us_template_zip_codes.xlsx' }],
    },
    widget_audiences: {
      enable: { dev: true, staging: true, prod: true },
      show_delivery_selector: { dev: true, staging: true, prod: false },
      version: Audience.AudienceFeatureVersion.V1,
      delivery_human_representation:
        Metadata.DELIVERY_HUMAN_REPRESENTATION.Month,
      max_AND: 10,
      max_OR: 10,
      by_index: {
        name: 'index',
        enable: true,
        max_value: 1000,
        default_min_value: 0,
        default_max_value: 100,
      },
      by_percentage: {
        name: 'percent',
        enable: false,
        selected_min_value: 10,
        selected_max_value: 80,
      },
    },
    widget_media_type: {
      enable: true,
      version: Media.MediaTypeFeatureVersion.V1,
      filter_digital: false,
      types: {
        l1panelclass: 'l1panelclass_id',
        l2product: 'l2product_id',
      },
    },
    widget_list_assets: {
      enable: true,
      fields: ['external_panel_id', 'name', 'impressions'],
      sortBy: 'name',
      add_copy_btn: false,
      switch_to_list_view: false,
    },
    widget_optimize_distribution: {
      enable: false,
      disable_production: false,
      types: [
        Metadata.TYPES_OPTIMIZE_DISTRIBUTION.Impressions,
        Metadata.TYPES_OPTIMIZE_DISTRIBUTION.Audience,
      ],
      prioritary_regions: [1, 2, 3, 4, 5, 6, 7, 8],
      excluded_regions: [15],
      max_number_assets: {
        enable: true,
        value: 10,
      },
      min_distance_between_assets: {
        enable: true,
        units: 'yards',
        max_distance: 100,
        selected_distance: 1,
      },
    },
    widget_proximity: {
      enable: true,
      version: Proximity.ProximityFeatureVersion.V1,
      modal: {
        show_brands: true,
        show_custom_pois: true,
        show_tags: false,
        show_locations: true,
        search_by: ['CATEGORIES', 'BRANDS', 'CUSTOM_POIS', 'LOCATIONS'],
      },
      pois_isochrones_fields: ['geoid', 'country_id', 'geom'],
      types: {
        radius: {
          id: 'RADIUS',
          name: 'RADIUS',
          enable: true,
          units: 'miles',
          min_distance: 0,
          max_distance: 25,
          double_buffer: false,
          selected_min_distance: 0,
          selected_max_distance: 1,
          max_assets_per_poi: {
            enable: false,
            options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
          },
        },
        driving: {
          id: 'DRIVING',
          name: 'DRIVING',
          enable: false,
          options: [300, 900, 1800, 3600],
          selected: 300,
        },
        walking: {
          id: 'WALKING',
          name: 'WALKING',
          enable: false,
          options: [300, 900, 1800, 3600],
          selected: 300,
        },
      },
      max_isochrones: 1000,
    },
    widget_packages: {
      enable: false,
      price_range: [2000, 5000],
    },
  },
}
