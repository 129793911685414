import { Auth, Package, Plan } from '@workspaces/types'
import { UserPermissions } from '../permissions.manager.type'
import ResolverBasic from './resolver-basic'

/**
 * 📢 Admin role represents someone from CCF-Admin agency
 */
class PermissionResolverFI extends ResolverBasic implements UserPermissions {
  private getPackageFromPlan(plan: Plan.Plan): Package.PlanPackage {
    if (plan.package === undefined || plan.package === null) {
      throw new Error('Plan package is not defined')
    }

    if (typeof plan.package === 'string') {
      throw new Error('Plan package is not deserialized')
    }

    return plan.package
  }

  canUploadCustomPOIs(): boolean {
    return true
  }

  canEditAnyCustomPOIGroup(): boolean {
    return false
  }

  permitComments(planOwner: string): boolean {
    return planOwner === this.getName() || Auth.Role.Admin === this.getRole()
  }

  canSavePlan(
    planOwner: string,
    planId: string | undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isPlanPublic: boolean,
  ): boolean {
    if (!planId) {
      return true
    }
    return this.getName() === planOwner || this.getRole() === Auth.Role.Admin
  }

  canSavePlanAsPublic(): boolean {
    return true
  }

  static DeleteablePlanStates: Package.State[] = [
    Package.State.Draft,
    Package.State.Expired,
    Package.State.Cancelled,
  ]

  canDeletePlan(plan: Plan.Plan): boolean {
    const planPackage = this.getPackageFromPlan(plan)
    const planOwner = plan.created_by
    const userCanDelete =
      this.getName() === planOwner || this.getRole() === Auth.Role.Admin
    const isPlanDeletable = PermissionResolverFI.DeleteablePlanStates.includes(
      planPackage.state,
    )
    return userCanDelete && isPlanDeletable
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canDeleteCustomGeoboundary(owner: string): boolean {
    return false
  }

  canDeleteCustomPOIGroup(owner: string): boolean {
    return this.isOwnerOrAdmin(owner)
  }

  canEditPlanPrice(): boolean {
    return this.getRole() === Auth.Role.Admin
  }

  canBookPlanPackage(plan: Plan.Plan): boolean {
    const planPackage = this.getPackageFromPlan(plan)

    if (this.isAnonymous()) {
      return false
    }

    return (
      !this.isAdmin() &&
      planPackage.state === Package.State.Draft &&
      planPackage.id !== undefined
    )
  }

  canConfirmBookingPlanPackageByAdmin(plan: Plan.Plan): boolean {
    const planPackage = this.getPackageFromPlan(plan)
    return this.isAdmin() && planPackage.state === Package.State.BookingRequest
  }

  canConfirmBookingPlanPackageByAgency(plan: Plan.Plan): boolean {
    const planPackage = this.getPackageFromPlan(plan)

    if (
      !this.isAdmin() &&
      planPackage.state === Package.State.BookingConfirmedByAdmin
    ) {
      const expirationTime = planPackage.expirationForAgencyBookingConfirmation
      if (expirationTime === undefined) {
        console.warn(
          'Trying to decide is plan can be confirmed without expiration booking timestamp',
        )
        return false
      }

      const nowInMillis = new Date().getTime()
      const isExpired = nowInMillis - expirationTime.getTime() >= 0
      return !isExpired
    }

    return false
  }

  canFinalizeConfirmBookingPlanPackageByAdmin(plan: Plan.Plan): boolean {
    const planPackage = this.getPackageFromPlan(plan)

    return (
      this.isAdmin() &&
      planPackage.state === Package.State.BookingConfirmedByAgency
    )
  }

  canCancelPlanPackageByAdmin(plan: Plan.Plan): boolean {
    const planPackage = this.getPackageFromPlan(plan)

    // if (!this.isAdmin() && plan.package.state === Package.State.Booked) {
    //   const expirationTime = plan.package.expirationForAgencyCancellation
    //   if (expirationTime === undefined) {
    //     console.warn(
    //       'Trying to decide is plan can be confirmed without an expiraction cancellation  timestamp',
    //     )
    //     return false
    //   }
    //   const nowInMillis = new Date().getTime()
    //   const isExpired = nowInMillis - expirationTime.getTime() > 0
    //   return !isExpired
    // }
    const expectedState =
      planPackage.state === Package.State.BookingConfirmedByAdmin ||
      planPackage.state === Package.State.Booked

    return this.isAdmin() && expectedState
  }

  canShowWidgetUploadAssets(): boolean {
    return this.isAdmin()
  }

  static EditableDatesStates: Package.State[] = [
    Package.State.Draft,
    Package.State.BookingRequest,
    Package.State.ReBooking,
  ]

  canEditPlanPackageDates(plan: Plan.Plan): boolean {
    const planPackage = this.getPackageFromPlan(plan)
    const planPackageState = planPackage.state

    return PermissionResolverFI.EditableDatesStates.includes(planPackageState)
  }
}

export default PermissionResolverFI
