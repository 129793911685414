import {
  Metadata,
  SharedPlan,
  Plan,
  Asset,
  Environment,
} from '@workspaces/types'
import { SharePlanService } from '@workspaces/services'
import { sendInfoMessageToUser } from '@/helpers/message/message.helper'
import { deserializePlan } from '@/helpers/plan.helper'
import { Geometry } from '@/plan/geometries/geoboundary.type'
import { convertPointsToGeoJsonPoint } from '@/layers/custom-pois/custom-pois.helper'
import { FetchLayerDataResult } from '@deck.gl/carto/typed/api/maps-v3-client'
import { FORMATS } from '@deck.gl/carto/typed'

export default {
  async createSharedPlan(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    planId: string,
    password: string | undefined,
  ): Promise<string> {
    const params: SharedPlan.CreateSharedPlanParameters = {
      planId,
      password: password || undefined,
      appVersion: environment.isTesting() ? meta.flavour_testing : meta.flavour,
    }
    const response = await SharePlanService.createSharePlan(
      meta,
      environment,
      params,
    )
    return response
  },

  async getAssets(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    planId: string,
    password: string | undefined,
    assetsOffset?: number,
    token?: string,
    iv?: string,
  ): Promise<SharedPlan.GeoJsonAsset[] | SharedPlan.RemoteAssetResolution> {
    const params: SharedPlan.CreateSharedPlanParameters = {
      planId,
      appVersion: environment.isTesting() ? meta.flavour_testing : meta.flavour,
      password: password || undefined,
      assetsOffset: assetsOffset || undefined,
      token: token || undefined,
      iv: iv || undefined,
    }
    try {
      const response = await SharePlanService.getAssets(
        meta,
        environment,
        params,
      )
      return response
    } catch (error) {
      const errorTyped = error as Error
      sendInfoMessageToUser({
        titleLocaleKey: '',
        msgLocaleKey: '',
        title: 'Error loading plan',
        msg: errorTyped.message,
      })
      console.error(error)
      if (assetsOffset === 0) {
        return { count: 0, assets: [] }
      }
      return []
    }
  },

  async getPlan(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    planId: string,
    password: string | undefined,
  ): Promise<Plan.Plan> {
    const params: SharedPlan.CreateSharedPlanParameters = {
      planId,
      appVersion: environment.isTesting() ? meta.flavour_testing : meta.flavour,
      password: password || undefined,
    }
    const response = await SharePlanService.getPlan(meta, environment, params)
    const plan = deserializePlan(response)
    return plan
  },

  async getGeometries(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    planId: string,
    password: string | undefined,
  ): Promise<Geometry[]> {
    const params: SharedPlan.CreateSharedPlanParameters = {
      planId,
      appVersion: environment.isTesting() ? meta.flavour_testing : meta.flavour,
      password: password || undefined,
    }
    const response = await SharePlanService.getGeometries(
      meta,
      environment,
      params,
    )
    return response
  },

  async getCustomPOIs(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    planId: string,
    password: string | undefined,
    activeSubPlanFiltersIndex: number,
  ): Promise<Partial<FetchLayerDataResult>> {
    const params: SharedPlan.CreateSharedPlanParameters = {
      planId,
      appVersion: environment.isTesting() ? meta.flavour_testing : meta.flavour,
      password: password || undefined,
    }
    const data = await SharePlanService.getCustomPOIs(
      meta,
      environment,
      params,
      activeSubPlanFiltersIndex,
    )
    const dataFormatted = convertPointsToGeoJsonPoint(data)
    const dataToReturn = {
      format: FORMATS.GEOJSON,
      data: dataFormatted,
    }
    return dataToReturn
  },

  async getAssetById(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    planId: string,
    password: string | undefined,
    assetId: string,
  ): Promise<Partial<Asset.AssetFull>[]> {
    const params: SharedPlan.CreateSharedPlanParameters = {
      planId,
      appVersion: environment.isTesting() ? meta.flavour_testing : meta.flavour,
      password: password || undefined,
    }
    const data: Partial<Asset.AssetFull> = await SharePlanService.getAssetById(
      meta,
      environment,
      params,
      assetId,
    )

    return [data]
  },
  async getInsights(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    planId: string,
    password: string | undefined,
  ): Promise<SharedPlan.SharedPlanInsights> {
    const params: SharedPlan.CreateSharedPlanParameters = {
      planId,
      appVersion: environment.isTesting() ? meta.flavour_testing : meta.flavour,
      password: password || undefined,
    }
    const data: SharedPlan.SharedPlanInsights =
      await SharePlanService.getInsights(meta, environment, params)
    return data
  },
}
