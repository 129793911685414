import {
  ASSETS,
  CLUSTER_ASSETS,
  EXCLUDED_ASSETS,
  EXCLUDED_CLUSTER_ASSETS,
} from '@/layers'
import layerService from '@/layers/layerService'

const latestZoomValues: number[] = []

function median(numbers: number[]): number {
  const sorted = numbers.slice().sort((a, b) => a - b)
  const middle = Math.floor(sorted.length / 2)
  if (sorted.length % 2 === 0) {
    return (sorted[middle - 1] + sorted[middle]) / 2
  } else {
    return sorted[middle]
  }
}

function mouseZoomDebounce(zoomValue: number): number {
  latestZoomValues.push(zoomValue)
  if (latestZoomValues.length > 6) {
    latestZoomValues.shift()
  }
  const zoom = median(latestZoomValues)
  // console.log('>> zoomDebounce', zoomValue, zoom)
  return zoom
}

export function shouldAssetSimpleLayerBeVisible(
  switchLayersZoomValue: number,
  currentZoom: number,
): boolean {
  return currentZoom >= switchLayersZoomValue
}

function isExcludedLayerVisible(): boolean {
  return (
    layerService.isVisible(EXCLUDED_ASSETS) ||
    layerService.isVisible(EXCLUDED_CLUSTER_ASSETS)
  )
}

export function manageAssetsLayersVisibility(
  switchLayersZoomValue: number,
  currentZoom: number,
) {
  // If assets layers is hidden do nothing
  if (
    !layerService.isVisible(CLUSTER_ASSETS) &&
    !layerService.isVisible(ASSETS)
  ) {
    return
  }

  const zoomValue = mouseZoomDebounce(currentZoom)
  // console.debug('manageAssetsLayersVisibility', switchLayersZoomValue, currentZoom, zoomValue)
  layerService.setZoom(zoomValue)
  if (
    zoomValue >= switchLayersZoomValue &&
    layerService.isVisible(CLUSTER_ASSETS)
  ) {
    console.debug('🔷 Switching to Simple Assets Layer')
    layerService.hideLayer(CLUSTER_ASSETS, {}, false)
    layerService.showLayer(ASSETS)
    if (isExcludedLayerVisible()) {
      console.debug('🔷 Switching to Simple Excluded Assets Layer')
      layerService.hideLayer(EXCLUDED_CLUSTER_ASSETS, {}, false)
      layerService.showLayer(EXCLUDED_ASSETS)
    }
  } else if (
    zoomValue < switchLayersZoomValue &&
    layerService.isVisible(ASSETS)
  ) {
    console.debug('🔶 Switching to Cluster Asset Layer')
    layerService.hideLayer(ASSETS, {}, false)
    layerService.showLayer(CLUSTER_ASSETS)
    if (isExcludedLayerVisible()) {
      console.debug('🔶 Switching to Cluster Excluded Assets Layer')
      layerService.hideLayer(EXCLUDED_ASSETS, {}, false)
      layerService.showLayer(EXCLUDED_CLUSTER_ASSETS)
    }
  }
}
